<template>
  <article id="wrap" :class="{buy_talk: hasNowSell}" class="">

    <AppHeader theme="white">
      <div v-if="talkRoom" class="talk_room_name">{{ talkRoom.name }}</div>
    </AppHeader>

    <ItemBanner :talkRoom="talkRoom" :nowSellItems="nowSellItems" v-if="hasNowSell"
                @modalOpenBuy="modalOpenBuy"></ItemBanner>

    <AppChatRoom :socket="socket" :talkRoom="talkRoom" :likes="likes"
                 :nowSellItems="nowSellItems" v-if="initialized"
                 @updateLikes="updateLikes" @modalOpenBuy="modalOpenBuy">
    </AppChatRoom>
<!--        <router-view :socket="socket" :talkRoom="talkRoom" :likes="likes" v-if="initialized" @openModalComment="openModalComment"></router-view>-->

    <div class="talk_fixed">
      <div class="bt_reload"><a @click="reload"><img src="@/assets/img/talk/bt_reload.png" alt="更新ボタン"></a></div>
      <div class="bt_form comment_btn">
        <a @click="openModalPost">
          <img src="@/assets/img/talk/icon_post.png" alt="メッセージ投稿アイコン">コメントしてみよう！
        </a>
      </div>
      <div class="bt_up_down">
        <div class="bt_up"><a @click="scrollTop"><img src="@/assets/img/talk/bt_move_up.png" alt="未読に移動ボタン"></a></div>
        <div class="bt_down"><a @click="scrollBottom"><img src="@/assets/img/talk/bt_move_down.png" alt="最新に移動ボタン"></a></div>
      </div>
    </div>

    <modal
        class="modal-comment" name="modal-comment" :adaptive="true" :scrollable="true"
        height="100%" width="100%"
        :shiftX="0" :shiftY="0"

        :styles="{borderRadius:'0', overflowY: 'scroll', background: '#ffffff'}">

      <div class="modal-comment-container">
        <Comment v-if="initialized && chat_id" :socket="socket" :talkRoom="talkRoom" :likes="likes"
                 class="comments" @updateLikes="updateLikes"></Comment>
        <div class="talk_fixed">
          <!--          <div class="bt_reload"><a @click="reload"><img src="@/assets/img/talk/bt_reload.png" alt="更新ボタン"></a></div>-->
          <div class="bt_form comment_btn"><a @click="openModalPost">
            <img src="@/assets/img/talk/icon_post.png" alt="メッセージ投稿アイコン">コメントしてみよう！</a>
          </div>
          <!--          <div class="bt_up_down">-->
          <!--            <div class="bt_up"><a @click="scrollTop"><img src="@/assets/img/talk/bt_move_up.png" alt="未読に移動ボタン"></a>-->
          <!--            </div>-->
          <!--            <div class="bt_down"><a @click="scrollBottom"><img src="@/assets/img/talk/bt_move_down.png" alt="最新に移動ボタン"></a>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </modal>
    <modal
        class="modal-wrapper" name="modal-user" id="modal-user" :adaptive="true"
        height="auto" width="90%" :maxWidth="600" :scrollable="true" :styles="{borderRadius:'10px'}">
      <div class="modal-window">
        <UserProfile :talkRoom="talkRoom"></UserProfile>
      </div>
    </modal>
    <modal
        class="modal-wrapper" name="modal-preview" id="modal-preview" :reset="true" :adaptive="true" :resizable="true"
        width="100%" height="100%" :scrollable="true" :styles="{backgroundColor:'transparent'}">
      <a @click="$modal.hide('modal-preview')" class="modal-overlay2"></a>
      <ModalPreviewImage></ModalPreviewImage>
      <a @click="$modal.hide('modal-preview')" class="modal-close">
        <img src="@/assets/img/talk/icon_close.png" alt="モーダル閉じるボタン">
      </a>
    </modal>
    <modal
        name="modal-post-delete" :adaptive="true" :scrollable="true"
        height="auto" width="95%" :maxWidth="600" :styles="{borderRadius:'10px'}">
      <div class="modal-window">
        <div class="modal-content">
          <div class="txt_12">メッセージを削除しますか？</div>
          <div class="bt_yes_no">
            <div class="bt_delete"><a @click="deleteRemark">削除</a></div>
            <div class="bt_cancel"><a @click="$modal.hide('modal-post-delete')">キャンセル</a></div>
          </div>
        </div>
      </div>
    </modal>
    <modal
        name="modal-post-report" :adaptive="true" :scrollable="true"
        height="auto" width="95%" :maxWidth="600" :styles="{borderRadius:'10px'}">
      <div class="modal-window">
        <div class="modal-content">
          <div class="txt_12">メッセージ通報しますか？</div>
          <div class="bt_yes_no">
            <div class="bt_delete"><a @click="reportRemark">通報</a></div>
            <div class="bt_cancel"><a @click="$modal.hide('modal-post-report')">キャンセル</a></div>
          </div>
        </div>
      </div>
    </modal>
    <modal
        name="modal-post-reported" :adaptive="true" :scrollable="true"
        height="auto" width="95%" :maxWidth="600" :styles="{borderRadius:'10px'}">
      <div class="modal-window">
        <div class="modal-content">
          <div class="txt_12">通報しました！</div>
          <div class="bt_yes_no">
            <div class="bt_cancel"><a @click="$modal.hide('modal-post-reported')">OK</a></div>
          </div>
        </div>
      </div>
    </modal>
    <modal name="modal-post-edit"
           class="wrapper-post-edit"
           width="230px"
           height="auto"
           :adaptive="true" :scrollable="true">
      <ModalPostEdit :selectedRemark="selectedRemark" :isMineSelectedRemark="isMineSelectedRemark"
                     @editPost="editPost" @openModalPostDelete="openModalPostDelete" @openModalPostReport="openModalPostReport"></ModalPostEdit>
    </modal>
    <modal class="modal-post01" name="modal-post01" :adaptive="true" :scrollable="true"
           height="auto" width="95%" :maxWidth="600" :styles="{borderRadius:'10px'}"
           :class="{master_post: isArtistOrOfficial}">
      <TalkRoomPost class="modal-window"
                    :talk_room="talkRoom"
                    :socket="socket"></TalkRoomPost>
    </modal>
    <modal name="modal-buy" :adaptive="true" :scrollable="true" id="modal-buy" height="auto" width="600" :styles="{borderRadius:'11px'}">
      <BuyModal @modalClose="$modal.hide('modal-buy')"
                @updateSellItemCartValue="updateSellItemCartValue"
                :nowSellItems="nowSellItems"
                :selectedItemId="selectedItem.id" class="modal-window" v-if="selectedItem"></BuyModal>
    </modal>
  </article>
</template>

<script>
import { io } from 'socket.io-client';
import AppHeader from '@/components/AppHeader';
import TalkRoomPost from '@/components/TalkRoom/Post';
import AppChatRoom from '@/views/AppChatRoom';
import Comment from '@/components/TalkRoom/Comment';
import UserProfile from '@/components/TalkRoom/UserProfile';
import ItemBanner from '@/components/TalkRoom/ItemBanner';
import BuyModal from '@/components/TalkRoom/BuyModal';
import ModalPostEdit from '@/components/TalkRoom/ModalPostEdit';
import ModalPreviewImage from '@/components/TalkRoom/ModalPreviewImage';
import axios from 'axios';
import moment from 'moment';
// import moment from 'moment';
export default {
  name: 'Chat',
  components: {
    AppHeader,
    TalkRoomPost,
    AppChatRoom,
    Comment,
    UserProfile,
    ItemBanner,
    BuyModal,
    ModalPostEdit,
    ModalPreviewImage
  },
  computed: {
    isMineSelectedRemark () {
      // -1 未選択 0 他人のメッセージ 1 自分のメッセージ
      if (this.$store.state.talkRoom.selectedRemark && this.$store.state.loginUser) {
        if (this.$store.state.talkRoom.selectedRemark.user.id === this.$store.state.loginUser.id) {
          return 1;
        } else {
          return 0;
        }
      } else {
        return -1;
      }
    },
    chat_id () {
      return this.$route.params.chat_id;
    },
    hasJoin () {
      if (!this.talkRoom) return;
      const index = this.$store.state.loginUser.talk_rooms.findIndex(talk_room => {
        return talk_room.id == this.talkRoom.id
      });
      return index > -1;
    },
    /**
     * 期限切れフラグ
     * @returns {boolean}
     */
    isExpired () {
      if (!this.talkRoom) return;
      if (this.isArtistOrOfficial) return false;
      const user_talk_room = this.$store.state.loginUser.talk_rooms.find(talk_room => talk_room.id == this.talkRoom.id);
      if (!user_talk_room) return true;
      if (!user_talk_room.subscription) return false;
      if (!user_talk_room.subscription.last_paid_at) return true;
      const now = moment();
      const aMonthAgo = now.subtract(1, 'M');
      return aMonthAgo.isAfter(moment(user_talk_room.subscription.last_paid_at));
    },
    isArtistOrOfficial () {
      let user_type = 'normal';
      if (!this.talkRoom) return;
      if (this.talkRoom.artist_id === this.$store.state.loginUser.artist_id) {
        user_type = 'artist';
      }
      if (this.$store.state.loginUser.is_official) {
        user_type = 'official';
      }
      return user_type === 'artist' || user_type === 'official';
    },
    cart () {
      return this.$store.state.cart;
    },
    selectedRemark () {
      return this.$store.state.talkRoom.selectedRemark;
    }
  },
  data() {
    return {
      socket: null,
      initialized: false,
      talkRoom: null,
      likes: null,
      hasNowSell: false,
      nowSellItems: null,
      selectedItem: null,
    }
  },
  created () {
    this.initialize();
  },
  provide: function () {
    return {
      scrollTop: this.scrollTop,
      scrollBottom: this.scrollBottom,
    }
  },
  methods: {
    async initialize() {

      const talkRoom = this.$route.params.talk_room;
      const response = await this.$http.get(`talk_room/${talkRoom}`);
      this.talkRoom = response.data;

      if (!this.hasJoin || this.isExpired) {
        this.$router.push({name: 'mypage'});
        return;
      }

      console.log(`socket io to ${process.env.VUE_APP_CHAT_SERVER}`);
      this.socket = io(`https://${process.env.VUE_APP_CHAT_SERVER}`);
      // this.socketInitialize();

      this.initialized = true;  // 自分のお気に入り履歴を読み込む前に表示させてしまおう

      // お気に入り
      const likesResponse = await axios.get(
          `https://${process.env.VUE_APP_CHAT_SERVER}/talk_rooms/${talkRoom}/likes`,
          {
            params: {'user_id': this.$store.state.loginUser.id},
          },
      );
      this.likes = likesResponse.data.likes;

      // 現在販売中の商品
      this.checkNowSell();

      // コメント
      if (this.$route.params.chat_id) {
        this.$modal.show('modal-comment');
      } else {
        this.$modal.hide('modal-comment');
      }
    },
    disconnect () {
      this.socket.emit('disconnect_user');
    },
    reload () {
      location.reload();
    },
    setInfinityScroll () {
      this.infiniteScrollObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if ( ! entry.isIntersecting ) return;
          this.prevRemarks()
        });
      });
      this.infiniteScrollObserver.observe(document.querySelector('#prevScrollPoint'));
    },
    scrollTop() {
      // this.prevRemarks();
      const top = 0;
      window.scroll({top, behavior: 'smooth'});
    },
    scrollBottom() {
      this.$nextTick(() => {
        var element = document.documentElement;
        // 調整値300
        var bottom = element.scrollHeight - element.clientHeight;
        console.log(window.innerWidth)
        if (window.innerWidth <= 400) {
          // contentElementを使った処理
          bottom = element.scrollHeight - element.clientHeight - 300;
        }
        window.scroll({top: bottom, behavior: 'smooth'});
      });
    },
    openModalPost() {
      this.$store.commit('setTalkRoomSelectedRemark', null);
      this.$modal.show('modal-post01');
    },
    editPost() {
      if (this.isMineSelectedRemark !== 1) return;
      this.$modal.hide('modal-post-edit');
      this.$modal.show('modal-post01');
    },
    openModalPostDelete () {
      if (this.isMineSelectedRemark !== 1) return;
      this.$modal.hide('modal-post-edit');
      this.$modal.show('modal-post-delete')
    },
    openModalPostReport () {
      if (this.isMineSelectedRemark === 1) return;
      this.$modal.hide('modal-post-edit');
      this.$modal.show('modal-post-report')
    },
    async deleteRemark() {
      const response = await axios.delete(`https://${process.env.VUE_APP_CHAT_SERVER}/messages/${this.$store.state.talkRoom.selectedRemark.id}`);
      this.$modal.hide('modal-post-delete');
      this.socket.emit('message', response.data);
    },
    async reportRemark() {
      console.log('reportRemark');
      this.$store.commit('setLoading');
      await axios.post(
          `https://${process.env.VUE_APP_CHAT_SERVER}/report/${this.$store.state.talkRoom.selectedRemark.id}`,
          {user_id: this.$store.state.loginUser.id, limit_ban: this.talkRoom.limit_ban},
      );
      this.$store.commit('resetLoading');
      this.$modal.hide('modal-post-report')
      this.$modal.show('modal-post-reported')
    },
    updateLikes(message){
      const isMine = (message.user_id == this.$store.state.loginUser.id) ? true : false;
      if (isMine && message.add > 0) {
        this.$set(this.likes, message.chat_id, true);
      } else if (isMine && message.add < 0) {
        this.$delete(this.likes, message.chat_id);
      }
    },
    async checkNowSell () {
      const talk_room_id = this.talkRoom.id;
      const response = await this.$http.get(`talk_room/${talk_room_id}/now_sell`);
      this.nowSellItems = response.data
      this.hasNowSell = !!this.nowSellItems.length;
      this.nowSellItems.forEach(item => {
        item.cartValue = 0;
      })
    },
    modalOpenBuy(item) {
      // console.log(item);
      const selectedItemId = item.id;
      this.selectedItem = this.nowSellItems.find(item => item.id === selectedItemId);
      if (!this.selectedItem) {
        this.$swal('販売終了', 'このアイテムは販売終了しています');
        return;
      }
      this.selectedItem.cartValue = 1;
      this.$modal.show('modal-buy');
    },
    updateSellItemCartValue (idx, cartValue) {
      this.nowSellItems.splice(idx, 1, {...this.nowSellItems[idx], cartValue});

      // // カートにあるかどうか
      // const inCart = !!this.cart.find(obj => obj.id === this.nowSellItems[idx].id);
      //
      // // 個数が1以上でカートの中にないならカートに追加
      // if (cartValue > 0 && !inCart) {
      //   console.log('個数が1以上でカートの中にない');
      //   this.$store.commit('addCart', this.nowSellItems[idx]);
      // }
      // // 個数が0でカートにあるならカートから削除
      // else if (cartValue === 0 && inCart) {
      //   console.log('個数が0でカートにある');
      //   this.$store.commit('removeCart', this.nowSellItems[idx]);
      // }
      // else {
      //
      // }
    }
  },
  watch: {
    $route (to) {
      // console.log(to.params.chat_id);
      if (to.params.chat_id) {
        this.$modal.show('modal-comment');
      } else {
        this.$modal.hide('modal-comment');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.talk_room_name {
  font-size: 13px;
  color: #fff!important;
}
#wrap {
  padding: 0;
  .tab_area label.active {
    background: #2c516b;
    color: #fff;
  }
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
}

.wrapper-post-edit {
  z-index: 10009;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10px;
  text-align: center;
}

#modal-preview {
  .modal-overlay2 {
    /* z-index: 10; */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
  }

  .img_preview {
    box-sizing: border-box;
    /* display: inline-block; */
    z-index: 20;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    video {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .modal-close {
    z-index: 20;
    position: absolute;
    right: 20px;
    bottom: 20px;

    img {
      width: 36px;
      height: 35px;
    }
  }

  .vm--overlay {
    background: rgba(0, 0, 0, 0.9);
  }
}

.talk_fixed {
  pointer-events:none;
  a {
    pointer-events: auto;
  }
}
.modal-comment {
  .modal-comment-container {
    height: 100%;
    max-width: 680px;
    margin: 0 auto;
    .panel_area {
      padding: 0;
    }
    .talk_fixed {
      display: block;
      position: sticky;
      bottom: 15px;
    }
  }
}
.user_detail-error {
  text-align: center;
  font-size: 12rem;
  font-weight: bold;
}

#modal-buy .modal-window {
  width: 100%;
}


#drawer-icon span {
    background: black;
    border-radius: 2px;
    display: block;
    height: 2px;
    /*	height: 16%;*/
    /*	left: 50%;*/
    /*	margin: -8% 0 0 -42%;*/
    position: absolute;
    top: 50%;
    transition: all 0.3s ease-in-out;
    width: 100%;
    /*	width: 84%;*/
}
#drawer-icon span::before,
#drawer-icon span::after {
    -webkit-transform: rotate(0);
    background: black!important;
    border-radius: 2px;
    content: "";
    display: block;
    height: 2px;
    /*	height: 100%;*/
    /*	left: 50%;*/
    /*	margin: -8% 0 0 -50%;*/
    position: absolute;
    /*	top: 50%;*/
    transform: rotate(0);
    transition: all 0.3s ease-in-out;
    width: 100%;
}

</style>
