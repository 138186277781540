<template>
  <li class="talk_like" @click="clickLikeButton">
    <img v-if="!isLoginUserLike" src="@/assets/img/talk/icon_owner_like.png" alt="Likeアイコン">
    <img v-if="isLoginUserLike" src="@/assets/img/talk/icon_owner_like_done.png" alt="Likeアイコン">
    <template v-if="like_count != null && like_count > 0">{{ like_count | number_format }}</template>
  </li>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  name: 'LikeButton',
  components: {  },
  props: {
    socket: Object,
    chat_id: String,
    like_count: Number,
    isLoginUserLike: Boolean,
  },
  data () {return {}},
  computed: {
  },
  filters: {
    number_format(num){
      if (num === undefined) return 0;
      return String(num).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    }
  },
  methods: {
    async clickLikeButton () {
      // mongoに格納
      axios.post(`https://${process.env.VUE_APP_CHAT_SERVER}/like`, {
        chat_id: this.chat_id,
        user_id: this.$store.state.loginUser.id,
        send_at: moment(),
      });
      // 通知イベント発生
      this.socket.emit('like', {
        talk_room_id: this.$route.params.talk_room,
        user_id: this.$store.state.loginUser.id,
        chat_id: this.chat_id,
        add: this.isLoginUserLike ? -1: 1,
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.talk_like {
  cursor: pointer;
}
</style>