<template>
  <div class="first-item" :class="{comment_root: isCommentRoot, artist: isArtist, secretariat: isSecretariat}" :id="id">
    <div class="remark-animation" :class="className">
      <template v-if="!isSecretariat && !isArtist">
        <div class="user_photo" :class="{ 'premium-user': isPremiumUser}" @click="openModalUser" v-if="user">
          <img :src="user.image | imageUrl | thumbnail" alt="ユーザ画像">
        </div>

        <ul class="col2">
          <li class="talk_title">
            <span v-if="user.name===null">...</span>
            <span v-else>{{ user.name }}</span>
          </li>
          <li class="talk_timestamp">{{ send_at | formattedDate }}
            <img v-if="modified_at" src="@/assets/img/talk/icon_owner_timestamp.png" alt="タイムスタンプアイコン">
          </li>
        </ul>

        <template v-if="!validShow">
          <p class="error">(このメッセージはプレミアム会員むけです)</p>
        </template>
        <template v-else-if="isBan">
          <p class="error">(このメッセージは表示できません)</p>
        </template>
        <template v-else-if="deleted_at">
          <p class="error">(このメッセージは削除されました)</p>
        </template>
        <template v-else>
          <p class="error" v-if="isPremiumOnly">(このメッセージはプレミアム会員むけです)</p>
          <div class="talk_message" v-html="messageFormated"></div>
          <AttachmentThumbs :attachments="attachments" @modalOpenPreview="openModalPreview"></AttachmentThumbs>
          <ul class="col3">
            <CommentButton
                v-if="!$route.params.chat_id"
                :socket="socket"
                :comment_count="comment_count"
                :chat_id="id"
                :talk_room_id="talk_room.id"></CommentButton>
            <LikeButton
                ref="userLikeButton"
                v-if="likes"
                :socket="socket"
                :chat_id="id"
                :like_count="like_count"
                :isLoginUserLike="likes && likes.hasOwnProperty(id) ? likes[id] : false"
                @update:like_count="markedLike($event)"></LikeButton>
            <li class="talk_margin"></li>
            <li class="talk_edit"><a @click="modalPostOpenEdit">
              <img src="@/assets/img/talk/icon_owner_edit.png" alt="編集アイコン">
            </a></li>
          </ul>
        </template>
      </template>
      <template v-if="isArtist || isSecretariat">
        <ul class="col2">
          <li class="col2L">
            <div class="user_photo" @click="openModalUser" v-if="user">
              <img :src="user.image | imageUrl | thumbnail" alt="ユーザ画像">
            </div>
          </li>
          <li class="col2R">
            <ul class="col2">
              <li class="talk_title">{{ user.name }}
                <img src="@/assets/img/talk/icon_official.png" alt="公式アイコン">
              </li>
              <li class="talk_timestamp">{{ send_at | formattedDate }}
                <img v-if="modified_at && !isSecretariat" src="@/assets/img/talk/icon_owner_timestamp.png" alt="タイムスタンプアイコン">
                <img v-if="modified_at && isSecretariat" src="@/assets/img/talk/icon_unei_timestamp.png" alt="タイムスタンプアイコン">
              </li>
            </ul>

            <template v-if="!validShow">
              <p class="error">(このメッセージはプレミアム会員むけです)</p>
            </template>
            <template v-else-if="isBan">
              <p class="error">(このメッセージは表示できません)</p>
            </template>
            <template v-else-if="deleted_at">
              <p class="error">(このメッセージは削除されました)</p>
            </template>
            <template v-else>
              <p class="error" v-if="isPremiumOnly">(このメッセージはプレミアム会員むけです)</p>
              <div class="talk_message" v-html="messageFormated"></div>
              <AttachmentThumbs :attachments="attachments" :digital_items="digital_items"
                                :haveBoughtDigitalContents="haveBoughtDigitalContents"
                                @modalOpenPreview="openModalPreview"></AttachmentThumbs>
              <div v-if="items && items.length">
                <div class="talk_images" v-for="item in items" :key="item.id">
                  <div class="talk_image" v-for="photo in item.item_photos" :key="photo">
                    <!--<a @click="openModalPreview(photo.url)" class="image">-->
                      <img :src="photo | imageUrl" alt="トーク画像01">
                    <!--</a>-->
                  </div>
                  <div v-if="!item.item_photos || !item.item_photos.length">
                    <img src="@/assets/img/signup/add_photo2.png" alt="">
                  </div>
                </div>
                <div class="buy_bt mb_10" @click="$emit('modalOpenBuy')"><a>
                  <img src="@/assets/img/buy/icon_cart.png" alt="カートアイコン">購入する
                </a></div>
              </div>
              <div v-if="digitalContents.length">
                <template v-if="!haveBoughtDigitalContents">
                  <div class="buy_bt mb_10"><a @click="buyDigital">
                    <img src="@/assets/img/buy/icon_look.png" alt="見るアイコン">見る</a>
                  </div>
                </template>
                <div v-else>
                  <div class="buy_bt bought mb_10"><a href="javascript:void(0)">
                    <img src="@/assets/img/buy/icon_look.png" alt="見るアイコン">購入済み</a>
                  </div>
                </div>
              </div>
              <ul class="col3">
                <CommentButton
                    v-if="!$route.params.chat_id && !isSecretariat"
                    :socket="socket"
                    :comment_count="comment_count"
                    :chat_id="id"
                    :talk_room_id="talk_room.id"></CommentButton>
                <LikeButton
                    ref="likeButton"
                    v-if="likes && !isSecretariat"
                    :socket="socket"
                    :chat_id="id"
                    :like_count="like_count"
                    :isLoginUserLike="likes && likes.hasOwnProperty(id) ? likes[id] : false"
                    @update:like_count="markedLike($event)"></LikeButton>
                <li class="talk_margin"></li>
                <li class="talk_edit"><a @click="modalPostOpenEdit">
                  <img v-if="isArtist" src="@/assets/img/talk/icon_owner_edit.png" alt="編集アイコン">
                  <img v-if="isSecretariat" src="@/assets/img/talk/icon_unei_edit.png" alt="編集アイコン">
                </a></li>
              </ul>
            </template>
          </li>
        </ul><!--.col2-->
      </template>
    </div>
    <hr class="new_line" id="unread" v-if="!isLast && isLastRead">
  </div>
</template>

<script>
import moment from 'moment';
import CommentButton from './CommentButton';
import LikeButton from './LikeButton';
import AttachmentThumbs from './AttachmentThumbs';

export default {
  name: 'Remark',
  components: { CommentButton, LikeButton, AttachmentThumbs },
  props: {
    _id: String,  // mongoDBでのID
    id: String,
    message: String,
    like_count: Number,
    comment_count: Number,
    report_count: Number,
    isPremiumOnly: Boolean,
    talk_room: Object,
    send_at: String,
    deleted_at: String,
    modified_at: String,
    user: Object,
    attachments: Array,
    likes: Object,
    comments: Object,
    user_status: String,  // マスター、管理者など
    socket: Object,
    isCommentRoot: Boolean,
    isLast: Boolean,
    isBan: Boolean,
    items: Array,
    digital_items: Array,
    price: Number,
    talk_room_id: Number,
    // talk_rooms: Array,
    // mode: {
    //   validator: function (value) {
    //     return ['holder', 'selector'].indexOf(value) !== -1;
    //   },
    // },
  },
  data () {
    return {
      selectedUser: null,
      preview_image: null,
      isLastRead: false,
    }
  },
  computed: {
    storedDigitalContents () {
      return this.$store.state.storedDigitalContents;
    },
    className () {
      const classes = {};
      if (this.isSecretariat) {
        classes.talk_unei = true;
      } else
      if (this.isArtist) {
        classes.talk_balloon = false;
        classes.talk_others = false;
        classes.talk_owner = true;
      } else {
        if (this.isOwn) {
          classes.talk_balloon = true;
          classes.talk_you = true;
        }
        if (this.isOther) {
          classes.talk_balloon = true;
          classes.talk_others = true;
        }
      }
      return classes;
    },
    isOwn () {
      if (this.user && this.user.id === this.$store.state.loginUser.id) {
        return true;
      }
      return false;
    },
    isOther () {
      if (this.user && this.user.id !== this.$store.state.loginUser.id) {
        return true;
      }
      return false;
    },
    isSecretariat () {
      return !this.user || this.user_status === 'secretariat';
    },
    isArtist () {
      return this.user_status === 'artist';
    },
    isPremiumUser () {
      return this.user_status === 'premium_user';
    },
    messageFormated () {
      let str = this.message;
      str = str.replace(/\r\n/g, "<br>");
      str = str.replace(/(\n|\r)/g, "<br>");
      return str;
    },
    subscription_type () {
      const user_talk_room = this.$store.state.loginUser.talk_rooms.find(talk_room => talk_room.id === this.talk_room.id);
      return user_talk_room.subscription.type;
    },
    /**
     * ■プレミアム会員向け投稿の表示
     ・運営者（＝公式バッジの付いたユーザー）
     ・トークルームに紐づくマスターに属するマスターユーザー
     ・特別(premium)な月額決済加入ユーザー
     * @returns {boolean}
     */
    validShow () {
      let user_type = 'normal';
      if (this.talk_room.artist_id === this.$store.state.loginUser.artist_id) {
        user_type = 'artist';
      }
      if (this.$store.state.loginUser.is_official) {
        user_type = 'official';
      }

      let valid = true;
      if (this.isPremiumOnly) {
        if (user_type === 'normal' && this.subscription_type === 'normal') {
          valid = false;
        }
      }
      return valid;
    },
    digitalContents () {
      if (!this.digital_items || this.digital_items.indexOf(true) === -1) return [];
      let digitalContents = [];
      for (let i = 0; i < this.digital_items.length; i++) {
        if (this.digital_items[i] && this.attachments[i]) {
          digitalContents.push(this.attachments[i]);
        }
      }
      return digitalContents;
    },
    haveBoughtDigitalContents () {
      if (!this.storedDigitalContents || !this.storedDigitalContents.length) return false;
      return !!this.digitalContents.find(item => this.storedDigitalContents.indexOf(item) > -1);
    }
  },
  filters: {
    formattedDate (val) {
      return moment(val).format('YYYY/MM/DD HH:mm');
    },
  },
  created () {
      const read_id = this.$store.state.talkRoom.lastReadTalkRoomRemarks[this.talk_room.id];
      if (this.id === read_id) {
        this.isLastRead = true;
      }
  },
  mounted () {
    // アーティストの書き込みに対して自動いいね
    if (this.isArtist && !this.$store.state.loginUser.artist_id) {
      const target = this.$refs.likeButton;
      if (target) {
        let options = {
          root: null,
          rootMargin: '173px 0px 86px 0px',  // top, right, bottom, left
          threshold: 1.0
        }
        setTimeout(() => {
          const handler = (entries) => {
            if (entries[0].isIntersecting) {
              const already = this.likes && Object.prototype.hasOwnProperty.call(this.likes, this.id) ? this.likes[this.id] : false;
              if (!already) {
                this.$emit('pushLike', {
                  chat_id: this.id,
                  send_at: moment().format(),
                  user_id: this.$store.state.loginUser.id
                })
              }
              observer.unobserve(target.$el);
            }
          }
          const observer = new window.IntersectionObserver(handler, options);
          observer.observe(target.$el)
        }, 2000)

      }
    }

    // ユーザーの書き込みに対して自動いいね(本人以外)
    if (this.isOther) {
      const targetUser = this.$refs.userLikeButton;

      if (targetUser) {
        let options = {
          root: null,
          rootMargin: '173px 0px 86px 0px',  // top, right, bottom, left
          threshold: 1.0
        }
        setTimeout(() => {
          const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
              const already = this.likes && Object.prototype.hasOwnProperty.call(this.likes, this.id) ? this.likes[this.id] : false;
              if (!already) {
                this.$emit('pushLike', {
                  chat_id: this.id,
                  send_at: moment().format(),
                  user_id: this.$store.state.loginUser.id
                });
                observer.unobserve(targetUser.$el);
              }
            }
          }, options);
          observer.observe(targetUser.$el);
        }, 2000);
      }
    }
  },
  methods: {
    markedLike (event) {
      this.$emit('update:like_count', event);
    },
    setTalkRoomSelectedRemark () {
      this.$store.commit('setTalkRoomSelectedRemark', {
        id:this.id,
        message:this.message,
        send_at: this.send_at,
        user: this.user,
        comment_count: this.comment_count,
        like_count: this.like_count,
        attachments: this.attachments,
        isPremiumOnly: this.isPremiumOnly,
        items: this.items,
        digital_items: this.digital_items,
      });
    },
    modalPostOpenEdit () {
      this.setTalkRoomSelectedRemark();
      this.$nextTick(() => {
        this.$modal.show('modal-post-edit');
      });
    },
    openModalPreview ($event) {
      this.$store.commit('setTalkRoomPreviewImage', $event);
      this.setTalkRoomSelectedRemark();
      this.preview_image = $event;
      this.$nextTick(() => {
        this.$modal.show('modal-preview');
      });
    },
    async openModalUser () {
      const response = await this.$http.get(`users/${this.user.id}`);
      this.selectedUser = response.data;
      this.$store.commit('setTalkRoomSelectedUser', response.data);
      this.$nextTick(() => {
        this.$modal.show('modal-user');
      });
    },
    buyDigital () {
      const digitalContents = {
        artist_id: this.talk_room.artist_id,
        type: 'digital',
        price: this.price,
        item_photos: this.digitalContents,
        value: 1,
      }
      this.setTalkRoomSelectedRemark();
      this.$store.commit('setCart', [digitalContents]);
      this.$router.push({name: 'cart-create'});
    }
  },
}
</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
}
.remark-animation{
  opacity: 0;

  /* アニメーション */
  animation: fadeIn .4s linear;
  animation-fill-mode: both;
}
.comment_root {
  background-color: #fff;
  &.artist {
    background-color: #fee066;
    .remark-animation {
      margin-left: 15px;
    }
  }
  .remark-animation {
    margin: 0 15px 15px 65px;
    &:before {
      width: 0;
    }
    &.talk_you .user_photo {
      right: initial;
      left: -50px;
    }
  }
}
.error {
  color: silver;
}
@keyframes fadeIn{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
.first-item:nth-child(1) {
    margin-top: 100px;
}
</style>
