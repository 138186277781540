<template>
  <li class="talk_comment" @click="clickCommentButton">
    <img src="@/assets/img/talk/icon_owner_comment.png" alt="コメントアイコン">
    <template v-if="comment_count != null && comment_count > 0">{{ comment_count | number_format }}</template>
  </li>
</template>

<script>
export default {
  name: 'CommentButton',
  components: {  },
  props: {
    socket: Object,
    chat_id: String,
    comment_count: Number,
    talk_room_id: Number,
  },
  data () {
    return {}
  },
  computed: {
  },
  filters: {
    number_format(num){
      if (num === undefined) return 0;
      return String(num).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    }
  },
  methods: {
    async clickCommentButton () {
      console.log('clickCommentButton');
      // this.$emit('openModalComment');
      this.$router.push({name: 'talk-comment', params: {talk_room: this.talk_room_id, chat_id: this.chat_id}});
    }
  },
}
</script>

<style lang="scss" scoped>
.talk_comment {
  cursor: pointer;
}
</style>
