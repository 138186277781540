<template>
  <section class="tab_list">

    <div class="tab_wrap">
      <FilterTab :nowSellItems="nowSellItems" :messages-filtering="messagesFiltering" @update="updateMessageFilter($event)"></FilterTab>
      <div class="tab_area_bg"></div>
      <div class="panel_area" id="panel_area">
        <div id="prevScrollPoint" v-if="!isFull">
          <!--            ここが表示されたらもっと読み込む-->
          <div class="spinner">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
          </div>
        </div>
        <div v-if="isFull && remarksRead.length === 0">
          <p style="text-align:center; margin-top: 50%;">メッセージはありません</p>
        </div>

        <TalkRoomRemark v-for="remark in remarksRead"
                        v-bind="remark"
                        :talk_room="talkRoom"
                        :likes="likes"
                        :key="`thread_read-${remark.id}`"
                        :socket="socket"
                        :is-last="($store.state.talkRoom.lastReadTalkRoomRemarks.hasOwnProperty(talkRoom.id) && remarksRead[remarksRead.length - 1].id === $store.state.talkRoom.lastReadTalkRoomRemarks[talkRoom.id])"
                        @modalOpenPostEdit="modalOpenPostEdit(remark)"
                        @update:like_count="remark.like_count=$event"
                        @modalOpenBuy="modalOpenBuy(remark)"
                        @pushLike="pushLike"
        ></TalkRoomRemark>
        <TalkRoomRemark v-for="remark in filteredRemarks"
                        v-bind="remark"
                        :talk_room="talkRoom"
                        :likes="likes"
                        :key="`thread_unread-${remark.id}`"
                        :socket="socket"
                        :is-last="true"
                        @modalOpenPostEdit="modalOpenPostEdit(remark)"
                        @update:like_count="remark.like_count=$event"
                        @modalOpenBuy="modalOpenBuy(remark)"
                        @pushLike="pushLike"
        ></TalkRoomRemark>

      </div><!--.panel_area-->
    </div><!--.tab_wrap-->
  </section><!--.tab_list-->
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import TalkRoomRemark from '@/components/TalkRoom/Remark';
import FilterTab from '@/components/TalkRoom/FilterTab';

export default {
  components: {TalkRoomRemark, FilterTab},
  inject: ['scrollTop', 'scrollBottom'],
  props: {
    socket: Object,
    talkRoom: Object,
    likes: Object,
    nowSellItems: Array,
  },
  data() {
    return {
      initialized: false,
      remarksRead: [],
      remarks: [],
      first_message: null,
      messagesFiltering: 'all',
      selectedUser: null,
      preview_image: null,
      infiniteScrollObserver: null,
      likeWaitingList: [],
      timer: null,
    };
  },
  computed: {
    filteredRemarks() {
        return this.remarks.filter(remark => {
          switch (this.messagesFiltering) {
            case 'only_artist':
              return remark.user_status === 'artist';
            case 'artist_mine':
              return remark.user.id === this.$store.state.loginUser.id;
            case 'all':
            default:
              return true;
          }
        });
    },
    isFull () {
      return this.remarksRead.length === 0 || !this.first_message || this.first_message.id === this.remarksRead[0].id
    }
  },
  created() {
    this.initialize();
  },
  beforeDestroy (){
    this.socket.removeListener('message', this.socketOnMessage);
    this.socket.removeListener('like', this.socketOnLike);
  },
  methods: {
    async initialize() {
      // 購入商品（デジコン）の取得
      const response = await this.$http.get('me/digital_contents');
      this.$store.commit('setStoredDigitalContents', response.data.map(v => v.url))

      this.socketInitialize();
      this.enterRoom();
    },
    socketInitialize() {
      this.socket.on('message', this.socketOnMessage);
      this.socket.on('like', this.socketOnLike);
    },
    async enterRoom() {
      this.$store.commit('setLoading');

      const talkRoomId = Number(this.$route.params.talk_room);
      this.$store.commit('setLastReadTalkRoom', talkRoomId);
      // const entryMessage = this.$store.state.loginUser.nick + "さんが入室しました。";
      this.socket.emit('join', {talk_room_id: talkRoomId});
      // this.socket.emit("client_to_server_broadcast", {
      //   talk_room_id : talkRoomId,
      //   message : entryMessage
      // });

      // mongoDBから取得
      const options = {};
      if (this.$store.state.talkRoom.selectedRemark) {
        options.params = {
          prevId: this.$store.state.talkRoom.selectedRemark._id
        }
      }
      const resMessages = await axios.get(`https://${process.env.VUE_APP_CHAT_SERVER}/talk_rooms/${talkRoomId}/messages`, options);
      this.remarksRead = resMessages.data.messages;
      this.first_message = resMessages.data.first_message;

      // this.remarks.push({message: '入室しました', send_at: moment().format() });

      this.$store.commit('resetLoading');
      // if (this.$store.state.talkRoom.selectedRemark) {
        // const elm = document.getElementById(this.$store.state.talkRoom.selectedRemark.id);
        // if (elm) {
          // @todo 購入したデジコンのチャットにスクロール移動
          // console.log(this.$store.state.talkRoom.selectedRemark.id, elm.getBoundingClientRect().top, window.scrollY,  document.documentElement.clientHeight, elm.clientHeight);
          // const y = elm.getBoundingClientRect().top + window.scrollY
          //     - document.documentElement.clientHeight
          //     + elm.clientHeight
          //     + 90
          // ;
          // window.scroll({top: y, behavior: 'smooth'});
        // }
      // } else {
        this.scrollBottom();
      // }

      if (!this.infiniteScrollObserver) {
        setTimeout(() => {
          this.setInfinityScroll();
        }, 500);
      }

      this.$store.commit('setTalkRoomSelectedRemark', null);  // リセット
    },
    leaveRoom() {
      this.socket.emit('leave', {talk_room_id: Number(this.$route.params.talk_room)});
    },
    scrollUnread() {
      this.$nextTick(() => {
        const panelArea = document.querySelector('#panel_area');
        const targetElement = document.querySelector('#unread');
        // 画面上部から要素までの距離
        const rectTop = targetElement.getBoundingClientRect().top;
        // スクロール位置に持たせるバッファ
        const buffer = 100;
        const top = rectTop - buffer;
        panelArea.scrollTo({top, behavior: 'smooth'});
      });
    },
    /**
     * 無限スクロール
     * #prevScrollPointが画面表示されたらメッセージ追加読み込みをする
     */
    setInfinityScroll() {
      this.infiniteScrollObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (!entry.isIntersecting) return;
          this.prevRemarks();
        });
      });
      const element = document.querySelector('#prevScrollPoint');
      if (element) this.infiniteScrollObserver.observe(element);
    },
    async prevRemarks() {
      const prevScrollHeight = document.documentElement.scrollHeight;

      // mongoDBから取得
      if (this.remarksRead.length === 0) return;
      const talkRoomId = Number(this.$route.params.talk_room);
      const params =  {
        prevId: this.remarksRead[0]._id,
        filter: this.messagesFiltering,
        user_id: this.$store.state.loginUser.id
      };
      const resMessages = await axios.get(
          `https://${process.env.VUE_APP_CHAT_SERVER}/talk_rooms/${talkRoomId}/messages`, {params},
      );
      if (resMessages.data.messages.length === 0) {
        // 全件取得したらスクロール監視削除
        // this.infiniteScrollObserver.unobserve(document.querySelector('#prevScrollPoint'));
      } else {
        this.remarksRead = resMessages.data.messages.concat(this.remarksRead);
      }
      this.$nextTick(() => {
        // 読み込みによりスクロール位置が動かないようにする
        // panelArea.scrollTop = panelArea.scrollHeight - prevScrollHeight;
        const top = document.documentElement.scrollHeight - prevScrollHeight;
        window.scroll({top});
      });
    },
    async updateMessageFilter (val) {
      // this.isFull = false;
      this.messagesFiltering = val;
      const talkRoomId = Number(this.$route.params.talk_room);

      // mongoDBから取得
      const url = `https://${process.env.VUE_APP_CHAT_SERVER}/talk_rooms/${talkRoomId}/messages`;
      const params = {
        filter: val,
        user_id: this.$store.state.loginUser.id,
      };
      const resMessages = await axios.get(url, {params});
      this.remarksRead = resMessages.data.messages;
      this.remarks = [];
      this.first_message = this.remarksRead[0];

      this.scrollBottom();
    },
    socketOnMessage(message){
      // コメントならカウントアップだけして非表示
      if (message.parent_id) {
        let existRemark;
        let foundAt = 'remarks';
        existRemark = this.remarks.find(remark => remark.id === message.parent_id);
        if (!existRemark) {
          existRemark = this.remarksRead.find(remark => remark.id === message.parent_id);
          foundAt = 'remarksRead';
        }
        if (existRemark) {
          const remark = {...existRemark, comment_count: existRemark.comment_count + 1};
          if (foundAt === 'remarks') {
            this.remarks.splice(this.remarks.indexOf(existRemark), 1, remark);
          } else {
            this.remarksRead.splice(this.remarksRead.indexOf(existRemark), 1, remark);
          }
        }
        return;
      }

      // どこまで読んだか記憶
      this.$store.commit('setLastReadTalkRoomRemarks', {
        talk_room_id: this.talkRoom.id,
        chat_id: message.id
      })

      let isOld = false;
      let existRemark = null;
      if ( this.remarks.length > 0 && moment(message.send_at).isBefore(moment(this.remarks[0].send_at)) ){
        isOld = true;
      }
      if (
          this.remarks.length === 0 && this.remarksRead.length > 0
          && moment(message.send_at).isSameOrBefore(moment(this.remarksRead[this.remarksRead.length - 1].send_at))
      ) {
        isOld = true;
      }
      if (isOld) {
        // 既読リストに入ったメッセージの更新
        existRemark = this.remarksRead.find(remark => remark.id === message.id);
        if (existRemark) {
          this.remarksRead.splice(this.remarksRead.indexOf(existRemark), 1, message);
        }
      } else {
        const existRemark = this.remarks.find(remark => remark.id === message.id);
        if (existRemark) {
          // 未読リストメッセージの更新
          this.remarks.splice(this.remarks.indexOf(existRemark), 1, message);
        } else {
          // 新規メッセージ
          this.remarks.push(message);
          // @todo 自分のメッセージのみスクロール
          this.scrollBottom();
        }
      }
    },
    socketOnLike(message){
      if (message.chat_id) {
        let existRemark;
        let foundAt = 'remarks';
        existRemark = this.remarks.find(remark => remark.id === message.chat_id);
        if (!existRemark) {
          existRemark = this.remarksRead.find(remark => remark.id === message.chat_id);
          foundAt = 'remarksRead';
        }
        if (existRemark) {
          const remark = {...existRemark, like_count: existRemark.like_count + message.add};
          if (foundAt === 'remarks') {
            this.remarks.splice(this.remarks.indexOf(existRemark), 1, remark);
          } else {
            this.remarksRead.splice(this.remarksRead.indexOf(existRemark), 1, remark);
          }
          this.$emit('updateLikes', message);
        }
        return;
      }
    },
    modalOpenBuy (remark) {
      if (remark.items[0]) {
        this.$emit('modalOpenBuy', remark.items[0])
      }
    },
    debounce (fn, interval) {
      // console.log('debounce');
      clearTimeout(this.timer)
      this.timer = setTimeout(function() {
        fn()
      }, interval)
    },
    pushLike (param) {
      console.log('pushLike');
      this.likeWaitingList.push(param);
      this.debounce(this.sendLike, 1000);
    },
    sendLike () {
      // console.log(this.likeWaitingList);
      axios.post(`https://${process.env.VUE_APP_CHAT_SERVER}/likes`, this.likeWaitingList)
      this.likeWaitingList.forEach((obj) => {
        this.socket.emit('like', {
          talk_room_id: this.$route.params.talk_room,
          user_id: this.$store.state.loginUser.id,
          chat_id: obj.chat_id,
          add: 1,
        });
      });
      this.likeWaitingList = [];
    }
  },
};
</script>

<style lang="scss" scoped>
.panel_area {
/*  height: calc(100vh - 122px);
  overflow-y: scroll;*/
}

#modal-buy .modal-window {
  width: 100%;
}

.spinner {
  /*	margin: 100px auto;*/
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
  z-index: 20;
  position: relative;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
}
.spinner > div {
  background-color: #fff;
  height: 100%;
  width: 6px;
  display: inline-block;
  border-radius: 2px;
  margin: 0 1px;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}
.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}
@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
       transform: scaleY(1.0);
       -webkit-transform: scaleY(1.0);
     }
}

.is-active {
  border: 1px solid red;
}
</style>
